.logo {
    height: 40vmin;
    -webkit-transition: width .5s, height .5s;
            transition: width .5s, height .5s;
}

.logo:hover {
    height: 45vmin;
}

.ywam-logo {
    display: flex;
    height: 42px;
    line-height: 42px;
    /* font-family: gibson semibold,sans-serif; */
    color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -.02em;
    margin: 1rem 0 1rem 0;
    text-decoration: none;
    -webkit-transition: width .2s, height .2s, line-height .2s;
            transition: width .2s, height .2s, line-height .2s;
}

.ywam-logo span {
    margin-left: .5rem;
}

.ywam-logo:hover {
    height: 50px;
    line-height: 50px;
}

.link {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.donation-btn {
    margin: 2rem 0 1rem 0 !important;
}

.app-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@media (max-width: 600px) {
    .app-header {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .logo {
        height: 50vmin;
        margin-bottom: 1rem;
    }
    .logo:hover {
        height: 55vmin;
    }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
